/*------------------------------------------------------------------
Project:  ComingSoon
Version:  
Last change:  
Assigned to:  Bach Le
Primary use:  
-------------------------------------------------------------------*/



/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url('../fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: Lato-Regular;
  src: url('../fonts/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: Lato-Bold;
  src: url('../fonts/Lato/Lato-Bold.ttf');
}



/*//////////////////////////////////////////////////////////////////
[ RS PLUGIN ]*/
/*---------------------------------------------*/
.container {
  max-width: 1200px;
}



/*//////////////////////////////////////////////////////////////////
[ Form ]*/

.contact100-form {
  width: 300px;
  margin: 0 auto;
}

/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  background-color: #fff;
  height: 50px;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}

/*---------------------------------------------*/
.input100 {
  display: block;
  width: 100%;
  background: transparent;
}

input.input100 {
  height: 100%;
  padding: 0 22px 0 22px;
}


/*------------------------------------------------------------------
[ Focus Input ]*/

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  top: -2px;
  left: -2px;
  pointer-events: none;
  border: 2px solid #304365;
  border-radius: 5px;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.2);
  -moz-transform: scaleX(1.1) scaleY(1.2);
  -ms-transform: scaleX(1.1) scaleY(1.2);
  -o-transform: scaleX(1.1) scaleY(1.2);
  transform: scaleX(1.1) scaleY(1.2);
}

.input100:focus+.focus-input100 {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;

  font-family: Poppins-Regular;
  color: #c80000;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}


/*==================================================================
    TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT
==================================================================*/

/*==================================================================
[ Color ]*/
.cl0 {
  color: #fff;
}

.modo {
  color: #ddd !important;
  font-size: 18px;
  font-weight: 100 !important;
  font-family: Poppins-Regular;
  white-space: nowrap;
  margin: 33px 0px;
}

/*//////////////////////////////////////////////////////////////////
[ S-Text 0 - 15 ]*/

.s1-txt1 {}

/*---------------------------------------------*/
.s2-txt1 {
  font-family: Lato-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #555555;
}

.s2-txt2 {
  font-family: Lato-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
}

.s2-txt3 {
  font-family: Lato-Regular;
  font-size: 14px;
  line-height: 1.5;
  color: #999999;
}

.s2-txt4 {
  font-family: Lato-Regular;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
}


/*//////////////////////////////////////////////////////////////////
[ M-Text 16 - 25 ]*/

.m1-txt1 {
  font-family: Poppins-Regular;
  font-size: 24px;
  line-height: 1.5;
  color: #555555;
}

.m1-txt2 {
  font-family: Poppins-Bold;
  font-size: 24px;
  line-height: 1.5;
  color: #555555;
}


/*//////////////////////////////////////////////////////////////////
[ L-Text >= 26 ]*/

.l1-txt1 {}

/*---------------------------------------------*/
.l2-txt1 {
  font-family: Lato-Bold;
  font-size: 40px;
  line-height: 1;
  color: #fff;
}


.brain {
  transform: translateX(50px);
}

/*==================================================================
   SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE
==================================================================*/


/*//////////////////////////////////////////////////////////////////
[ Size ]*/
.size1 {
  width: 100%;
  min-height: 100vh;
}

.size2 {
  height: 100%;
  width: 71%;
}

.size3 {
  max-width: 45%;
  min-height: 100vh;
}

.size4 {
  width: 100%;
  height: 50px;
}

.size5 {
  width: 36px;
  height: 36px;
}

.size6 {
  width: 155px;
  height: 155px;
}

/*//////////////////////////////////////////////////////////////////
[ Width ]*/
.wsize1 {
  max-width: 650px;
}

.wsize2 {
  max-width: 380px;
}


/*//////////////////////////////////////////////////////////////////
[ Height ]*/
.hsize1 {
  min-height: 100vh;
}

body {
  background-color: #151c26;
  background: url('../images/damier-texture-horizontal.jpg');
  background-size: cover;
}


/*//////////////////////////////////////////////////////////////////
[ Background ]*/
.bg0 {
  background: none;
}

.bg0::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 66%;
  width: 1500px;
  height: 100%;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 41%, rgba(233, 237, 237, 1) 100%);
  transform-origin: bottom right;
  -webkit-transform: skewX(156deg);
  -moz-transform: skewX(156deg);
  -ms-transform: skewX(156deg);
  -o-transform: skewX(156deg);
  transform: skewX(156deg);
}


/*---------------------------------------------*/
.overlay1 {
  overflow: hidden;
}

.overlay1::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
}



.bg1 {
  background-color: #304365;
}

.bg3 {
  background-color: #3b5998;
}

.bg4 {
  background-color: #1da1f2;
}

.bg5 {
  background-color: #cd201f;
}

.bg-img1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}




/*//////////////////////////////////////////////////////////////////
[ Border ]*/
/*---------------------------------------------*/
.bo1 {
  border: 1px solid #ebebeb;
}

/*---------------------------------------------*/
.bor1 {
  border-radius: 5px;
}


/*==================================================================
   WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE  
==================================================================*/
.where1-parent {
  position: relative;
  z-index: 1;
}

.where1 {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
}

/*---------------------------------------------*/
.where2 {
  padding: 50px 50px 150px 320px;
}


/*==================================================================
 HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW 
==================================================================*/
.how1 {
  border-radius: 50%;
  font-size: 22px;
  color: #fff;
}

.how1:hover {
  background-color: #555555;
  color: #fff;
}

/*---------------------------------------------*/
.parallax100 {
  background-attachment: fixed;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 992px) {
  .parallax100 {
    background-attachment: inherit;
  }
}

/*---------------------------------------------*/
.placeholder0::-webkit-input-placeholder {
  color: #999999;
}

.placeholder0:-moz-placeholder {
  color: #999999;
}

.placeholder0::-moz-placeholder {
  color: #999999;
}

.placeholder0:-ms-input-placeholder {
  color: #999999;
}

/*---------------------------------------------*/
.wrap-pic1 {
  width: 50%;
}

.wrap-pic1 img {
  max-width: 100%;
}




/*//////////////////////////////////////////////////////////////////
[ Pseudo ]*/

/*------------------------------------------------------------------
[ Focus ]*/
.focus-in0:focus::-webkit-input-placeholder {
  color: transparent;
}

.focus-in0:focus:-moz-placeholder {
  color: transparent;
}

.focus-in0:focus::-moz-placeholder {
  color: transparent;
}

.focus-in0:focus:-ms-input-placeholder {
  color: transparent;
}


/*------------------------------------------------------------------
[ Hover ]*/
.hov-cl0:hover {
  color: #fff;
}

.hov-bg0:hover {
  background-color: #fff;
}

/*---------------------------------------------*/
.hov1:hover {
  background-color: #573170;
}






/*==================================================================
  RESPONSIVE RESPONSIVE RESPONSIVE RESPONSIVE RESPONSIVE RESPONSIVE
==================================================================*/

/*//////////////////////////////////////////////////////////////////
[ XXL ]*/
@media (max-width: 1600px) {
  .respon1 {
    max-width: 50%;
  }
}


/*//////////////////////////////////////////////////////////////////
[ XL ]*/
@media (max-width: 1200px) {
  .m-0-xl {
    margin: 0;
  }

  .m-lr-0-xl {
    margin-left: 0;
    margin-right: 0;
  }

  .m-lr-15-xl {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-0-xl {
    margin-left: 0;
  }

  .m-r-0-xl {
    margin-right: 0;
  }

  .m-l-15-xl {
    margin-left: 15px;
  }

  .m-r-15-xl {
    margin-right: 15px;
  }

  .p-0-xl {
    padding: 0;
  }

  .p-lr-0-xl {
    padding-left: 0;
    padding-right: 0;
  }

  .p-lr-15-xl {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-l-0-xl {
    padding-left: 0;
  }

  .p-r-0-xl {
    padding-right: 0;
  }

  .p-l-15-xl {
    padding-left: 15px;
  }

  .p-r-15-xl {
    padding-right: 15px;
  }

  .w-full-xl {
    width: 100%;
  }

  /*---------------------------------------------*/
  .respon1 {
    padding-left: 30px;
    padding-right: 30px;
  }
}


/*//////////////////////////////////////////////////////////////////
[ LG ]*/
@media (max-width: 992px) {
  .bg0::after {
    top: 75vw;
    right: 0;
    width: 1500px;
    height: calc(100% - 75vw);

  }

  .where2 {
    padding: 0px;
    height: 75vw;
  }

  .brain{
    width: 60vw;
    transform: none;
  }
  .logo {
    width: 30vw;
  }

  .overlay1::before {
    display: none;
  }

  .m-0-lg {
    margin: 0;
  }

  .m-lr-0-lg {
    margin-left: 0;
    margin-right: 0;
  }

  .m-lr-15-lg {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-0-lg {
    margin-left: 0;
  }

  .m-r-0-lg {
    margin-right: 0;
  }

  .m-l-15-lg {
    margin-left: 15px;
  }

  .m-r-15-lg {
    margin-right: 15px;
  }

  .p-0-lg {
    padding: 0;
  }

  .p-lr-0-lg {
    padding-left: 0;
    padding-right: 0;
  }

  .p-lr-15-lg {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-l-0-lg {
    padding-left: 0;
  }

  .p-r-0-lg {
    padding-right: 0;
  }

  .p-l-15-lg {
    padding-left: 15px;
  }

  .p-r-15-lg {
    padding-right: 15px;
  }

  .w-full-lg {
    width: 100%;
  }

  /*---------------------------------------------*/
  .respon1 {
    max-width: 650px;
    min-height: unset;
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
  }


  /*---------------------------------------------*/
  .respon2 {
    position: relative;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .respon2::after {
    top: 100%;
    right: 0;
    width: 100%;
    height: 10000px;
    transform-origin: top right;
    -webkit-transform: skew(180deg, 8deg);
    -moz-transform: skew(180deg, 8deg);
    -ms-transform: skew(180deg, 8deg);
    -o-transform: skew(180deg, 8deg);
    transform: skew(180deg, 8deg);
  }

}


/*//////////////////////////////////////////////////////////////////
[ MD ]*/
@media (max-width: 768px) {
  .m-0-md {
    margin: 0;
  }

  .m-lr-0-md {
    margin-left: 0;
    margin-right: 0;
  }

  .m-lr-15-md {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-0-md {
    margin-left: 0;
  }

  .m-r-0-md {
    margin-right: 0;
  }

  .m-l-15-md {
    margin-left: 15px;
  }

  .m-r-15-md {
    margin-right: 15px;
  }

  .p-0-md {
    padding: 0;
  }

  .p-lr-0-md {
    padding-left: 0;
    padding-right: 0;
  }

  .p-lr-15-md {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-l-0-md {
    padding-left: 0;
  }

  .p-r-0-md {
    padding-right: 0;
  }

  .p-l-15-md {
    padding-left: 15px;
  }

  .p-r-15-md {
    padding-right: 15px;
  }

  .w-full-md {
    width: 100%;
  }

  /*---------------------------------------------*/

}


/*//////////////////////////////////////////////////////////////////
[ SM ]*/
@media (max-width: 576px) {
  .m-0-sm {
    margin: 0;
  }

  .m-lr-0-sm {
    margin-left: 0;
    margin-right: 0;
  }

  .m-lr-15-sm {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-0-sm {
    margin-left: 0;
  }

  .m-r-0-sm {
    margin-right: 0;
  }

  .m-l-15-sm {
    margin-left: 15px;
  }

  .m-r-15-sm {
    margin-right: 15px;
  }

  .p-0-sm {
    padding: 0;
  }

  .p-lr-0-sm {
    padding-left: 0;
    padding-right: 0;
  }

  .p-lr-15-sm {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-l-0-sm {
    padding-left: 0;
  }

  .p-r-0-sm {
    padding-right: 0;
  }

  .p-l-15-sm {
    padding-left: 15px;
  }

  .p-r-15-sm {
    padding-right: 15px;
  }

  .w-full-sm {
    width: 100%;
  }

  /*---------------------------------------------*/


}


/*//////////////////////////////////////////////////////////////////
[ SSM ]*/
@media (max-width: 480px) {
  .m-0-ssm {
    margin: 0;
  }

  .m-lr-0-ssm {
    margin-left: 0;
    margin-right: 0;
  }

  .m-lr-15-ssm {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-0-ssm {
    margin-left: 0;
  }

  .m-r-0-ssm {
    margin-right: 0;
  }

  .m-l-15-ssm {
    margin-left: 15px;
  }

  .m-r-15-ssm {
    margin-right: 15px;
  }

  .p-0-ssm {
    padding: 0;
  }

  .p-lr-0-ssm {
    padding-left: 0;
    padding-right: 0;
  }

  .p-lr-15-ssm {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-l-0-ssm {
    padding-left: 0;
  }

  .p-r-0-ssm {
    padding-right: 0;
  }

  .p-l-15-ssm {
    padding-left: 15px;
  }

  .p-r-15-ssm {
    padding-right: 15px;
  }

  .w-full-ssm {
    width: 100%;
  }

  /*---------------------------------------------*/

}