html,
body {
  height: 100%;
}

.main-body {
  padding-top: 100px;
  min-height: calc(100vh - 300px);
}
.dropdown-with-icons #address > p {
  word-break: break-word;
}

.dropdown-with-icons #address > p {
  word-break: break-word;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
